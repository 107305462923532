const routeOptions = [
    {path: 'personalUnmet', name: 'personal_auth_unmet', url: '/authentication/personalUnmet', meta: {title: '个人认证条件不足'}},
    {path: 'personalAuth', name: 'personal_auth', url: '/authentication/personalAuth', meta: {title: '个人身份认证'}},
    {path: 'personalSuccess', name: 'personal_auth_success', url: '/authentication/personalSuccess', meta: {title: '个人身份认证成功'}},
    {path: 'enterpriseAuth', name: 'enterprise_auth', url: '/authentication/enterpriseAuth', meta: {title: '企业认证'}},
  ]
  //循环绑定路由
  const children = routeOptions.map(route => {
    return {
      ...route,
      component: () => import(/* webpackChunkName: "[request]" */ '@/views' + route.url + '.vue')
    }
  })
  
  export default {
    path: '/auth',
    name: 'auth',
    meta: {title: '认证中心'},
    component: () => import('@/layout/auth.vue'),
    children: children
  };